import Collapse from "components/Collapse";
import { useEffect, useState } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { apiUser } from "api";
import { getCookieAuth } from "lib/cookie";
import { useStore } from "components/ui";
import confirmLogoutImage from "assets/images/confirmLogoutImage.png";
import { Image, Button, Modal, Tooltip } from "antd";
import cn from "classnames";
import { togleMenu } from "lib/icons";
import { useTranslation } from "react-i18next";
import logo from "./../../../assets/images/logoIcon.png";
export default function Navbar({ items, togleSidebar, isNav, settingTheme }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { logOut } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  async function handleLogout() {
    try {
      await apiUser.logout({
        refresh_token: getCookieAuth()?.refresh_token,
      });
    } catch (error) {
      console.log({ error });
    } finally {
      logOut();
      navigate("/login");
    }
  }

  useEffect(() => {
    if (isNav === "left-0") {
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden";
      }
    }
    return () => {
      document.body.style.overflow = null;
      document.body.style.width = null;
    };
  }, [isNav]);

  const wapperClass = isNav ? "show__sider" : "hide__sider";
  const classTogleMenu = isNav ? "text-white right-2" : "text-dark-500 -right-10";

  const name = settingTheme?.name;
  const shouldShowTooltip = name?.length >= 12;

  return (
    <div
      className={`bg-primary-700 z-30 sticky h-screen border-r border-primary-300 top-0 shadow ease-in-out duration-300 ${wapperClass}`}
    >
      <div
        className={
          "relative border-b flex justify-between items-center border-primary-300 px-4 h-[63px] text-xl font-bold text-primary-50"
        }
      >
        <div className={cn("flex items-center", { "gap-4": isNav }, { "pl-1": !isNav })}>
          <img
            src={logo}
            alt="logo"
            className={`w-[44px] h-[39px] rounded`}
            onClick={() => navigate("/dashboard")}
          />
          {isNav && shouldShowTooltip ? (
            <Tooltip placement="bottom" title={name}>
              <div className="text-[#ffffff]">{name.slice(0, 12) + "..."}</div>
            </Tooltip>
          ) : (
            <div className="text-[#ffffff]">{name}</div>
          )}
        </div>

        <div
          onClick={togleSidebar}
          className={`absolute z-40  top-4 cursor-pointer p-2 ani rounded transition-all duration-300 ${classTogleMenu}`}
        >
          {togleMenu}
        </div>
      </div>
      <div className={isNav ? "overflow-y-auto __scroll" : ""}>
        <div className="flex flex-col py-4 text-[16px]">
          <ul className="space-y-2">
            {items.map((item) => {
              const { url, title, icon, children } = item;
              const isCurrent = location.pathname.includes(url);
              if (children && children.length > 0) {
                return <MenuItem {...item} key={url} location={location} isNav={isNav} />;
              } else {
                const activeClassName = isCurrent
                  ? "bg-primary-50 text-primary-600 font-bold hover:bg-primary-300"
                  : "text-primary-200 hover:bg-white/30";
                return (
                  <li key={url} className="px-4 mb-2">
                    <NavLink to={url}>
                      <div className={`${activeClassName} menu__item`}>
                        {icon}
                        <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap text-[16px]">
                          {t(title)}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
      {/* {isNav ? (
        <div className="px-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <div className="w-10 h-10 rounded-full">
                <img src={user.avatar} className="object-cover rounded-full" />
              </div>
              <div>
                <p className="text-[14px] font-semibold text-[#ffffff]">
                  {user.middle_name} {user.last_name}
                </p>
                <p className="max-w-[130px] truncate text-[12px] text-[#E1E8ED]">{user.email}</p>
              </div>
            </div>
            <div className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
              {iconLogout}
            </div>
          </div>
        </div>
      ) : (
        <div className="cursor-pointer flex justify-center" onClick={() => setIsModalOpen(true)}>
          {iconLogout}
        </div>
      )} */}

      <ModalConfirmLogout
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={handleLogout}
      />
    </div>
  );
}

function getCount(list) {
  let count = 0;
  list.forEach((element) => {
    count += 1;
    let __count = element.children?.length;
    if (__count > 0) count = count + __count;
  });
  return count;
}

function HoverSection({ listChildren, location, url, level }) {
  const { t } = useTranslation();

  return (
    <ul className="space-y-2 bg-primary-700 rounded-lg">
      {listChildren.map((item, kk) => {
        const titleChild = item.title;
        const urlChild = item.url;
        const activeClassName =
          location?.pathname === urlChild
            ? "text-primary-600  hover:bg-primary-300 text-white font-bold"
            : "text-primary-200 font-light";
        return (
          <NavLink to={item.url} key={kk}>
            <div className={`${activeClassName} menu__item sub__menu_2`}>
              <span className="text-ellipsis flex-auto whitespace-nowrap text-[16px] hover:text-white">
                {t(titleChild)}
              </span>
              {level === 2 && (
                <div className="hover-selection1">
                  <HoverSection listChildren={item.children} location={location} />
                </div>
              )}
            </div>
          </NavLink>
        );
      })}
    </ul>
  );
}

function MenuItem({ title, icon, url, children, location, isNav, isMax }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(children.find((i) => location.pathname.includes(i.url)));
  const isCurrent = location.pathname.includes(url);
  const isParentUrlActive = "/" + location.pathname.split("/")[1] === url;
  function onTogle() {
    setOpen((c) => !c);
  }
  const activeClassName = isCurrent ? "text-white font-medium" : "text-primary-200";
  const hoverActive = isCurrent && !isMax ? "hover:bg-primary-200" : "hover:bg-white/30";
  const classIsDown = isOpen ? "rotate-180" : "rotate-0";

  return (
    <div className={cn("sub__menu relative", { "border-l-[2px] border-primary-300": isMax })}>
      <li className={isMax ? "" : "px-4"}>
        <div
          onClick={onTogle}
          className={cn(
            `${activeClassName} ${hoverActive} rounded cursor-pointer h-11 px-4 flex items-center justify-between gap-4`,
            { "bg-primary-50": isParentUrlActive }
          )}
        >
          <div
            className={`flex w-full items-center gap-4 ${isParentUrlActive ? "text-primary-600 font-bold " : ""}`}
          >
            {!isMax && icon}
            <span
              className={`text-ellipsis flex-auto overflow-hidden whitespace-nowrap text-[16px] `}
            >
              {t(title)}
            </span>
          </div>
          {isNav && (
            <div
              className={cn(`${classIsDown} flex items-center transition-all duration-300`, {
                "text-primary-600": isParentUrlActive,
              })}
            >
              {icDown}
            </div>
          )}
          <div className="hover-selection">
            {!isNav && (
              <HoverSection listChildren={children} url={url} location={location} level={2} />
              // </ul>
            )}
          </div>
        </div>
      </li>
      <div className="h-[1px]"></div>
      <Collapse isOpen={isOpen} isShow={isNav} count={getCount(children)}>
        <ul className="p-2 pl-4 space-y-2">
          {children.map((item) => {
            if (item?.children?.length > 0)
              return (
                <MenuItem isMax={true} {...item} key={item.url} location={location} isNav={isNav} />
              );
            return (
              <li className="!mt-0" key={item.url}>
                <NavLink
                  to={`${item.url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center text-primary-50 gap-4 py-2 px-4 border-l-[2px] border-primary-50"
                      : "flex items-center text-primary-300 gap-4 py-2 px-4 border-l-[2px] border-primary-300 hover:text-white"
                  }
                >
                  <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap text-[16px]">
                    {t(item.title)}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </Collapse>
    </div>
  );
}

function ModalConfirmLogout({ onSubmit, isModalOpen, setIsModalOpen }) {
  const { t } = useTranslation();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal className="!max-w-[400px]" open={isModalOpen} onCancel={handleCancel} footer={<></>}>
      <div className="flex justify-center mt-10">
        <Image width={151} height={117} preview={false} src={confirmLogoutImage} />
      </div>
      <h3 className="text-[20px] font-bold text-[#1C3E4B] text-center mt-8 mb-3">
        {t("Confirm logout")}
      </h3>
      <p className="text-[#646970] text-center mb-5">{t("Are you sure you want to log out?")}</p>

      <div className="flex justify-between gap-4">
        <Button size="large" className="!rounded-lg w-full" onClick={handleCancel}>
          {t("Cancel")}
        </Button>
        <Button
          type="primary"
          danger
          size="large"
          className="!rounded-lg w-full"
          onClick={onSubmit}
        >
          {t("Log out")}
        </Button>
      </div>
    </Modal>
  );
}

const icDown = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1315 4.6875H2.86897C2.56116 4.6875 2.38929 5.0125 2.57991 5.23438L7.71116 11.1844C7.85804 11.3547 8.14085 11.3547 8.28929 11.1844L13.4205 5.23438C13.6112 5.0125 13.4393 4.6875 13.1315 4.6875Z"
      fill="currentColor"
    />
  </svg>
);
